@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    background-color: #f5f6ff;
    padding: 16px 24px;
}

.app-wrapper {
    width: 100%;
    height: 1000vh;
    display: flex;
    flex-direction: column;
    font-weight: 500;
}

.section-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.selection-wrapper {
    display: flex;
    width: 100%;
    margin-top: 6px;
}

input, select {
    height: 40px;
    border: 1px solid #c6c9ff;
    border-radius: 4px;
    padding: 0 6px;
    width: 100%;
}

input {
    margin-left: 16px;
}

input:hover,
input:focus {
    outline: 1px solid #269ffd;
}


.calculate-result {
    background-color: #8870e6;
    border-radius: 4px;
    border: 1px solid #8870e6;
    color: #fff;
    height: 44px;
    padding: 0 20px;
    width: 100%;
}

.error-message{
    color:red;
}

.result-wrapper{
    display: flex;
    flex-direction: column;
    margin: 16px 0;
}

.result-wrapper.selected{
    color:red
}

.button-wrapper > span {
    text-align: end;
    display: block;
    color: #149f5a;
    font-style: italic;
    margin-top: 6px;
}


.result-wrapper{
    font-size: 13px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.sup-wrapper > span{
    display:block;
    text-align: start;
}